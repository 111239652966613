.liberty-cls-background {
  // Placeholder image
  background-color: white;
  background-image: url("https://www.kleinanzeigen.de/liberty/liberty-js/placeholder-logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  // hide placeholder when filled
  &.liberty-filled,
  .liberty-filled & {
    background: none;

    &::before {
      display: none;
    }
  }
}

/* Use the server-side rendered data attribute `data-liberty-position-name` for selecting slots */
.liberty-cls-improved {
  @extend .liberty-cls-background;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

[data-liberty-position-name="home-billboard"],
[data-liberty-position-name="srpb-billboard"],
[data-liberty-position-name="srps-billboard"],
[data-liberty-position-name="lsrp-billboard"],
[data-liberty-position-name="zsrp-billboard"],
[data-liberty-position-name^="my"][data-liberty-position-name$="top-banner"],
[data-liberty-position-name^="vip"][data-liberty-position-name$="billboard"] {
  @extend .liberty-cls-improved;
  /* Fixed height avoids Cumulative Layout Shift (CLS) */
  min-height: 250px !important;
}

[data-liberty-position-name="srpb-top-banner"],
[data-liberty-position-name="zsrp-top-banner"],
[data-liberty-position-name="soi-top-banner"],
[data-liberty-position-name^="vip"][data-liberty-position-name$="gallery"] {
  @extend .liberty-cls-improved;
  /* Fixed height avoids Cumulative Layout Shift (CLS) */
  min-height: 90px !important;
}

.liberty-hide-unfilled {
  // hide slot when it's unfilled
  &.liberty-unfilled {
    // NOTE: Do not use display:none because it resulted in substantial less revenue for Kleinanzeigen!
    height: 0 !important;
    min-height: 0 !important;
    width: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
    overflow: hidden;
  }
}

// Avoid CLS on mweb SRP
#srp-results {
  [data-liberty-position-name="lsrp-result-list-0"],
  [data-liberty-position-name="lsrp-result-list-1"],
  [data-liberty-position-name="lsrp-result-list-2"],
  [data-liberty-position-name="lsrp-result-list-3"],
  [data-liberty-position-name="lsrp-result-list-4"],
  [data-liberty-position-name="lsrp-result-list-5"],
  [data-liberty-position-name="lsrp-result-list-6"],
  [data-liberty-position-name="lsrp-result-list-7"],
  [data-liberty-position-name="lsrp-result-list-8"],
  [data-liberty-position-name="srps-result-list-0"],
  [data-liberty-position-name="srps-result-list-1"],
  [data-liberty-position-name="srps-result-list-2"],
  [data-liberty-position-name="srps-result-list-3"],
  [data-liberty-position-name="srps-result-list-4"],
  [data-liberty-position-name="srps-result-list-5"],
  [data-liberty-position-name="srps-result-list-6"],
  [data-liberty-position-name="srps-result-list-7"],
  [data-liberty-position-name="srps-result-list-8"],
  [data-liberty-position-name="srpb-result-list-0"],
  [data-liberty-position-name="srpb-result-list-1"],
  [data-liberty-position-name="srpb-result-list-2"],
  [data-liberty-position-name="srpb-result-list-3"],
  [data-liberty-position-name="srpb-result-list-4"],
  [data-liberty-position-name="srpb-result-list-5"],
  [data-liberty-position-name="srpb-result-list-6"],
  [data-liberty-position-name="srpb-result-list-7"],
  [data-liberty-position-name="srpb-result-list-8"] {
    @extend .liberty-cls-improved;
    min-height: 117px !important;
  }
}

#srp-sticky {
  min-height: 50px;
}
